export const defaults = {
    page_title: "Victoria Furtuna | Виктория Фуртунэ - Телеграм.SITES",
    main_url: "https://tracker.furtuna-telegram.com/tracking202/redirect/go.php?acip=511",
    name: "Victoria Furtuna",
    tag: "@Victoria_Furtuna",
    main_avatar_url: "/img/main.jpeg",
    parsers:[
      {
        name: "Victoria Furtuna | Виктория Фуртунэ",
        parser: "FORTUNA",
        tag: "@Victoria_Furtuna",
        main_url: "https://tracker.furtuna-telegram.com/tracking202/redirect/go.php?acip=511",
        avatar_url: "/img/main.jpeg",
        contacts_phrase: "Вот что ты потеряшь, если...",
        new_messages: 11
      },
    ],
    posts: []
  }
